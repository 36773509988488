import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import allocationDetail from './allocationDetail';
export default {
  name: 'assetAllocation',
  components: {
    allocationDetail
  },
  watch: {
    currentPage: function() {
      this.getAssetAllocationGridData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getAssetAllocationGridData();
    }
  },
  data() {
    return {
      payload: null,
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      location: {
        text: null,
        value: null
      },
      locator: null,
      assetNo: null,
      showAllocationModal: false,
      rowData: null,
      rowItem: null,
      specIndex: null,
      assetAllocationData: [
        {
          serial_num: 'abc',
          locator: 'abc',
          asset_num: '1',
          item_desc: 'Testing 1',
          manufracturer: 'Vatika',
          asset_location_with_bin: 'temp data 1'
        },
        {
          serial_num: 'abc',
          locator: 'abc',
          asset_num: '12',
          item_desc: 'Testing 2',
          manufracturer: 'Vatika',
          asset_location_with_bin: 'temp data 2'
        },
        {
          serial_num: 'abc',
          locator: 'abc',
          asset_num: '123',
          item_desc: 'Testing 3',
          manufracturer: 'Vatika',
          asset_location_with_bin: 'temp data 3'
        },
        {
          serial_num: 'abc',
          locator: 'abc',
          asset_num: '1234',
          item_desc: 'Testing 4',
          manufracturer: 'Vatika',
          asset_location_with_bin: 'temp data 4'
        }
      ],
      assetAllocationFields: [
        {
          key: 'serial_num',
          label: 'Serial No.'
        },
        {
          key: 'locator'
        },
        {
          key: 'asset_num',
          label: 'Asset No.'
        },
        {
          key: 'item_desc',
          label: 'Item Description'
        },
        {
          key: 'manufracturer'
        },
        {
          key: 'asset_location_with_bin'
        }
      ]
    };
  },
  mounted() {},
  methods: {
    getAssetAllocationGridData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        location: this.location.value,
        locator: this.locator,
        asset_no: this.assetNo
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getAssetAllocationGridData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.assetAllocationData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      switch (this.vsetCode) {
        case appStrings.VALUESETTYPE.ASSET_LOCATION:
          this.location = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          break;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    showHideAllocationDetailModal(flag, item, index) {
      this.showAllocationModal = flag;
      if (flag) {
        this.rowItem = item;
        this.specIndex = index;
      }
    },
    closeModal(item) {
      this.assetAllocationData[this.specIndex].asset_location_with_bin = item;
      this.showAllocationModal = false;
    },
    clear() {
      this.location = {
        text: null,
        value: null
      };
      this.locator = null;
      this.assetNo = null;
      this.assetAllocationData = [];
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.location.value) {
        this.location = {
          text: null,
          value: null
        };
      }
    }
  },
  beforeDestroy() {}
};
